document.addEventListener("DOMContentLoaded", function() {
    var can_scroll;

  
    function validateRadios( obj ){
        var error_specific_radio = true;
        var objects_count = document.querySelectorAll( `input[name="${ obj.getAttribute("name") }"]` ).length
        if( objects_count > 1 ){
            document.querySelectorAll( `input[name="${ obj.getAttribute("name") }"]` ).forEach((inputElement) => {
                if(inputElement.checked){
                    error_specific_radio = false;
                }
            })

            if( !error_specific_radio )
                return true;
        }else{
            if( obj.checked )
                return true;
        }

        return false;

    }
    function validateCheckbox( value, name_element, objFormElement ){
        var error_specific_check = false;
        const checkboxesElements = objFormElement.querySelectorAll(`*[name="${name_element}"]`)
        if( checkboxesElements.length > 1 ){
            checkboxesElements.forEach((checkbox) => {
                if( checkbox.checked ){
                    error_specific_check = true;
                }
            })
            return error_specific_check;
        }else{
            if( value.checked ){
                return true
            }
        }

    }
    function validateField( value, aux_obj_1 ){
        var error = false;
        var class_attr = value.getAttribute('class');
        var can_evaluate = true;


        if(value.parentElement.classList.contains('hidden')){
            can_evaluate = false;
        }

        var name_element = value.getAttribute('name');
        
        if( typeof name_element !== "undefined" && name_element ){
            name_element = name_element.replace( "]", "" );
            name_element = name_element.replace( "[", "" );
        }

        if( can_evaluate && class_attr.indexOf( "check-required" ) == "-1" && (
            ( value.value == "" && ( value.getAttribute("type") == "text" || value.tagName == "textarea" ) ) ||
            ( value.getAttribute("type") == "checkbox" && !validateCheckbox( value, name_element,aux_obj_1 )) ||
            ( value.getAttribute("type") == "radio" && !validateRadios( value ) ) ||
            ( value.getAttribute("type") == "email" && !validateEmail( value) ) ||
            ( value.getAttribute("type") == "url"  && !validateUrl( value) ) ||
            ( value.getAttribute("type") == "date" && value.value == "" ) ||
            ( value.getAttribute("type") == "time" && value.value == "" ) ||
            ( value.getAttribute("type") == "number" && value.value == "" ) ||
            ( value.getAttribute("type") == "tel" && value.value == "" ) ||
            ( value.getAttribute("type") == "file" && value.value == "" && value.getAttribute("multiple")==undefined) ||
            ( value.getAttribute("type") == "file" && value.files.length==0 && value.getAttribute("multiple")=='multiple' ) ||
            ( value.tagName == "SELECT" && value.value == "" ) ||
            ( value.tagName == "TEXTAREA" && value.value == "" ) ) )

        {
            if( can_scroll && typeof form_error_scroll === 'undefined'){
            
                window.scrollTo({
                    top:  value.getBoundingClientRect().top + window.scrollY ,
                    behavior: "smooth"
                });
                can_scroll = false;

            }

            value.classList.add('error');
            if(value.parentElement.querySelectorAll( `#_${name_element}-error` ).length > 0){
                value.parentElement.querySelectorAll( `#_${name_element}-error` ).forEach((labelError) => {
                    labelError.remove();
                });
            }
            
            if(value.getAttribute("type") == "url" && !validateUrl( value)){
                if( value.value.trim() =='')
                    value.insertAdjacentHTML('afterend' , `<label id="_${name_element}-error" class="error" for="${name_element}">${getTextTranslate('This field is required.')}</label>`);  
                else
                    value.insertAdjacentHTML('afterend' , `<label id="_${name_element}-error" class="error" for="${name_element}">${getTextTranslate('Please enter a valid URL, starting with \'https://\'')}</label>`)
            }else if( value.getAttribute('type') == "radio" || value.getAttribute('type') == "checkbox" ) {
                value.insertAdjacentHTML('afterend' , `<label id="_${name_element}-error" class="error" for="${name_element}">${getTextTranslate('This field is required.')}</label>`);
            }else if(value.getAttribute('type')== "email" &&  Boolean(value.dataset.validate)===true){
                value.insertAdjacentHTML('afterend' , `<label id="_${name_element}-error" class="error" for="${name_element}">${getTextTranslate('Email and confirmation email must be the same.')}</label>`);
            }else{
                let error_message = getTextTranslate('This field is required.');
                if(value.value.length>0 && value.getAttribute('type')== "email")
                    error_message = getTextTranslate('Invalid Email');
                value.insertAdjacentHTML('afterend' , `<label id="_${name_element}-error" class="error" for="${name_element}">${error_message}</label>`);
            }
            
            error = true;
        }else if( class_attr.indexOf( "check-required" ) != "-1" && can_evaluate){
            
            error = true;

            value.querySelectorAll('input').forEach((input) =>{
                if( input.checked ){
                    error = false;
                }
            })

            if( error ){
                if(  value.parentElement.querySelectorAll( `#_${name_element}-error`).length == 0 &&  value.parentElement.querySelectorAll( "[class*='error']").length == 0 ) {
                    if( value.getAttribute('type') == "radio" || value.getAttribute('type') == "checkbox" ){
                        value.insertAdjacentHTML('afterend' , `<label id="_${name_element}-error" class="error" for="${name_element}">${getTextTranslate('This field is required.')}</label>`);
                    }else{
                        value.insertAdjacentHTML('afterend' , `<label id="_${name_element}-error" class="error" for="${name_element}">${getTextTranslate('This field is required.')}</label>`);
                    }
                }
            }else{
                value.querySelectorAll("[class*='error']" ).forEach((errorElement) =>{
                    errorElement.remove()
                })
                value.parentElement.querySelectorAll( "[class*='error']").forEach((errorElement) =>{
                    errorElement.remove()
                })
            }
        }else{
            value.classList.remove("error");
            if(value.parentElement.querySelector(`#_${name_element}-error`)){
                value.parentElement.querySelector(`#_${name_element}-error`).remove();
            }
            value.parentElement.querySelectorAll("*[class*='-error']").forEach((errorElement) =>{
                errorElement.remove()
            })
        }
        return error;
    }
    function validateEmail(id) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        var email = id.value
        var confirmValidate = Boolean(id.dataset.validate)
        if(confirmValidate===true && re.test(String(email).toLowerCase())===true){
            var idEmail = id.dataset.id_validate;
            if(document.getElementById(idEmail).value===email)
                return true;
            else
                return false;
        }else
            return re.test(String(email).toLowerCase());
    }
    function validateUrl(field) {
        const value = field.value
        return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(value);
    }
  

    window.callbackFormValidate  = function(obj){
        var aux_obj = obj;
        var error = false;
        var aux_obj_1 = obj.formElement;
        var last_border = "1px solid #000";

        if (typeof aux_obj.rules === "object") {
            for (var index in aux_obj.rules) {
              if (aux_obj.rules.hasOwnProperty(index)) {
                var aux = index.replace("]", "");
                aux = aux.replace("[", "");
          
                var inputElements = aux_obj_1.querySelectorAll('input[name="' + aux + '"]');
                var elementWithId = aux_obj_1.querySelector('#' + aux);
          
                inputElements.forEach(function(inputElement) {
                  inputElement.classList.add("required");
                });
          
                if (elementWithId) {
                  elementWithId.classList.add("required");
                }
              }
            }
        }

        aux_obj_1.querySelectorAll( ".required, .check-required" ).forEach(function(value , index){
            value.addEventListener('change' , function(){
                var aux = validateField( this, aux_obj_1 );
                if( aux ){
                    error = aux;
                }
            })

            value.addEventListener('keyup' , function(){
                var aux = validateField( this, aux_obj_1 );
                if( aux ){
                    error = aux;
                }
            })

        } )

        obj.formElement.addEventListener('submit' , function(event){
            event.preventDefault()
            var _this = obj.formElement;
            error = false;
            can_scroll = true;

            aux_obj_1.querySelectorAll( ".required, .check-required" ).forEach(function(value , index){
                var aux = validateField( value ,aux_obj_1);
                if( aux ){
                    error = aux;
                }
            } )

            if( !error ){
                var callback = aux_obj.submitHandler;
                callback( _this );
            }
            return false;
        })
    }

    function getTextTranslate(text){
        switch (langSelected) {
            case 'ar':
                if(text=='This field is required.')
                    return 'هذه الخانة مطلوبه.';
                else if(text=='Email and confirmation email must be the same.')
                    return 'يجب أن يكون البريد الإلكتروني والبريد الإلكتروني للتأكيد متماثلين.';
                else if(text=='Please enter a valid URL, starting with \'https://\'')
                    return 'يُرجى إدخال عنوان URL صالح يبدأ بـ \'https://\'';
                else if(text == 'Invalid Email' )
                    return 'البريد الإلكتروني غير صالح.';
                break;
            case 'es':
                if(text=='This field is required.')
                    return 'Este campo es requerido.';
                else if(text=='Email and confirmation email must be the same.')
                    return 'El correo electrónico y el correo electrónico de confirmación deben ser iguales.';
                else if(text=='Please enter a valid URL, starting with \'https://\'')
                    return 'Introduzca una URL válida que comience con \'https://\'';
                else if(text == 'Invalid Email' )
                    return 'Email inválido.';
                break;
            case 'it':
                if(text=='This field is required.')
                    return 'Questo campo è obbligatorio.';
                else if(text=='Email and confirmation email must be the same.')
                    return "L'e-mail e l'e-mail di conferma devono coincidere.";
                else if(text=='Please enter a valid URL, starting with \'https://\'')
                    return 'Inserite un URL valido che inizi con ‘https://’';
                else if(text == 'Invalid Email' )
                    return 'E-mail non valido.';
                break;
            case 'fr':
                if(text=='This field is required.')
                    return 'Ce champ est requis.';
                else if(text=='Email and confirmation email must be the same.')
                    return "L'e-mail et l'e-mail de confirmation doivent être identiques.";
                else if(text=='Please enter a valid URL, starting with \'https://\'')
                    return 'Veuillez saisir une URL valide commençant par « https:// »';
                else if(text == 'Invalid Email' )
                    return 'Adresse e-mail invalide.';
                break;
            case 'de':
                if(text=='This field is required.')
                    return 'Dieses Feld wird benötigt.';
                else if(text=='Email and confirmation email must be the same.')
                    return "E-Mail und Bestätigungs-E-Mail müssen identisch sein.";
                else if(text=='Please enter a valid URL, starting with \'https://\'')
                    return 'Bitte geben Sie eine gültige URL beginnend mit \'https://\' ein.';
                else if(text == 'Invalid Email' )
                    return 'Ungültige E-Mail.';
                break;
            case 'ru':
                if(text=='This field is required.')
                    return 'Это поле обязательно к заполнению.';
                else if(text=='Email and confirmation email must be the same.')
                    return 'Электронная почта и электронное письмо с подтверждением должны совпадать.';
                else if(text=='Please enter a valid URL, starting with \'https://\'')
                    return 'Введите действительный URL-адрес, который начинается с «https://»';
                else if(text == 'Invalid Email' )
                    return 'Неправильный адрес электронной почты.';
                break;
            case 'pt':
                if(text=='This field is required.')
                    return 'Este campo é obrigatório.';
                else if(text=='Email and confirmation email must be the same.')
                    return 'Email e email de confirmação devem ser os mesmos.';
                else if(text=='Please enter a valid URL, starting with \'https://\'')
                    return 'Por favor, insira um URL válido.';
                else if(text == 'Invalid Email' )
                    return 'E-mail inválido.';
                break;
            case 'tr':
                if(text=='This field is required.')
                    return 'Bu alan gereklidir.';
                else if(text=='Email and confirmation email must be the same.')
                    return 'E-posta ve onay e-postası aynı olmalıdır.';
                else if(text=='Please enter a valid URL, starting with \'https://\'')
                    return 'Lütfen \'https://\' ile başlayan geçerli bir URL girin.';
                else if(text == 'Invalid Email' )
                    return 'Gecersiz e-posta adresi.';
                break;
            case 'nl':
                if(text=='This field is required.')
                    return 'Dit veld is verplicht.';
                else if(text=='Email and confirmation email must be the same.')
                    return 'Dit e-mailadres staat al in ons archief.';
                else if(text=='Please enter a valid URL, starting with \'https://\'')
                    return 'Voer een geldige URL in die begint met \'https://\'';
                else if(text == 'Invalid Email' )
                    return 'Ongeldig e-mailadres.';
                break;
            case 'pl':
                if(text=='This field is required.')
                    return 'To pole jest wymagan.';
                else if(text=='Email and confirmation email must be the same.')
                    return 'Ten adres e-mail już figuruje w naszej bazie.';
                else if(text=='Please enter a valid URL, starting with \'https://\'')
                    return 'Wprowadź prawidłowy adres URL rozpoczynający się od „https://”';
                else if(text == 'Invalid Email' )
                    return 'Nieprawidłowy adres email.';
                break;
            case 'en':
                return text;
                break;
            default:
                return text;
                break;
        }
    }
    
} )