'use strict';

document.addEventListener("DOMContentLoaded", function () {
	'use strict';
	var lazyImages = [].slice.call(document.querySelectorAll('.lazyload, .lazybg, .lazyinlinebg, .animate-fade') || []);
	var active = false;

	function _intersectionObserver() {
		if (!active) {
			active = true;
			lazyImages.forEach(function (image) {
				var _imageBoundingClientRect = image.getBoundingClientRect();
				if (_imageBoundingClientRect.top <= window.innerHeight && _imageBoundingClientRect.bottom >= 0) {
					if( image.classList.contains('lazyload')  ) image.classList.contains('lazyinlinebg') ? image.style.backgroundImage = 'url(' + image.dataset.srcset + ')' : image.src = image.dataset.srcset;

					image.classList.add('visible');

                    if( image.classList.contains('animate-fade')  ){
                        image.classList.add('fade-in');
					}
					lazyImages = lazyImages.filter(function (_image) {
						return image !== _image;
					});
				}

				if (lazyImages.length === 0) {
					document.removeEventListener('scroll', _intersectionObserver);
					window.removeEventListener('resize', _intersectionObserver);
					window.removeEventListener('orientationchange', _intersectionObserver);
				}

				active = false;
			});
		}
	}

	if (lazyImages.length > 0) {
		document.addEventListener('scroll', _intersectionObserver);
		window.addEventListener('resize', _intersectionObserver);
		window.addEventListener('orientationchange', _intersectionObserver);
	}

	_intersectionObserver();
});
