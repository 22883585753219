/*CHECK IF IS MOBILE DEVICE
 ****************************************************************/
var isMobile = {
    Android: function () {
        return navigator.userAgent.match(/Android/i) && navigator.userAgent.match(/Mobile/i);
    },
    BlackBerry: function () {
        return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function () {
        return navigator.userAgent.match(/iPhone|iPod/i);
    },
    Opera: function () {
        return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function () {
        return navigator.userAgent.match(/IEMobile/i);
    },
    any: function () {
        return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
    }
};


/* Set cookies for offline conversions
 ****************************************************************/
function setCookie(name, value, days) {
    var date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    var expires = "; expires=" + date.toGMTString();
    document.cookie = name + "=" + value + expires;
}
function getParam(p) {
    var match = RegExp('[?&]' + p + '=([^&]*)').exec(window.location.search);
    return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
}
function readCookie(name) {
    var n = name + "=";
    var cookie = document.cookie.split(';');
    for (var i = 0; i < cookie.length; i++) {
        var c = cookie[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1, c.length);
        }
        if (c.indexOf(n) == 0) {
            return c.substring(n.length, c.length);
        }
    }
    return null;
}
function _gaLink(a) {
    url = a.href;
    _gaq.push(function () {
        if (a.target == '_blank') {
            window.open(_gat._getTrackers()[0]._getLinkerUrl(url));
        } else {
            _gaq.push(['_link', url]);
        }
    });
    return false;
}

if(window.jQuery && typeof jQuery.ui !== 'undefined'){
    // this created a label for the empty select in the datepicker
    function datePickerLabel(triggerIn, triggerOut){
        
        var CheckinLabel = triggerIn.length;
        var CheckoutLabel = triggerOut.length;
        
        if( CheckinLabel > 0 && CheckoutLabel > 0){
            
            var numberRandom1 = Math.floor((Math.random() * 5) + 0);
            var numberRandom2 = Math.floor((Math.random() * 10) + 6);
            
            
            var valSelect1 = triggerIn.find('.ui-datepicker-month').attr('id', 'ui-datepicker-month' + numberRandom1);
            var valSelect2 = triggerOut.find('.ui-datepicker-month').attr('id', 'ui-datepicker-month' + numberRandom2);
            
            var valSelectID1 = valSelect1.attr('id');
            var valSelectID2 = valSelect2.attr('id');
            
            $('.ui-datepicker-title').prepend('<label hidden>select date</label>');
            
            triggerIn.find('.ui-datepicker-title label').attr('for', valSelectID1);
            triggerOut.find('.ui-datepicker-title label').attr('for', valSelectID2);
                
        }	
        
    }
    
    datePickerLabel( $('#CheckIn'), $('#CheckOut'));
    // setTimeout(function(){ 
    //     $( ".hasDatepicker" ).each(function( index ) {
    //         $(this).attr('autocomplete','off');
    //     }); 
    // }, 3000);
}

/* Equalize Heights
 ****************************************************************/
// http://labs.eustasy.org/jquery.equalize/

function equalize(f, group, equalize) { // Define a new function
	'use strict'; // Be strict
	group = group || '.group'; // Set Group to Itself, OR .group
	equalize = equalize || '.equalize'; // Set Equalize to Itself, OR .equalize
	if($(group).length > 0){
		var groupCount = $(group).length; // Do we have groups? If so let's create a counter for them
	}
	$(group).each(function(){ // For each group class
		var highestBox = 0; // Clear the highest height
		$(equalize, this).css('height', 'auto'); // Set all the equalize classes to auto
		$(equalize, this).each(function(){ // For each equalize class
			if($(this).innerHeight() > highestBox) { // If it's height is bigger than the highest height
				highestBox = $(this).innerHeight(); // it's the new highest
			}
		}); // Finshed with this group?
		$(equalize,this).innerHeight(highestBox); // Set them to all be the highest
		groupCount--; // Decrement number of groups
	}); // Start again, or finished with all the groups?
	if(groupCount === 0){ // Is this the last group?
		console.log("Heights equalized");
		if (typeof f == "function") f(); else console.log(); // Callback support
	}
} // Finish defining the function

/*
$(function(){ // When the page has loaded
	'use strict'; // You should always be strict
	equalize(); // Run the function
});

window.onresize = function() { // And every time the window is resized
	'use strict'; // Always
	equalize(); // Here we go again
};
*/

function slideNextContent(btn,className){
	btn.click(function(event) {
		var $this = $(this);
        event.preventDefault();
        $this.toggleClass(className);
        $this.next().slideToggle();
	});
}
function showContent(btn,content,className){
	btn.click(function(event) {
        var $this = $(this);
		event.preventDefault();
        $this.toggleClass(className);
		content.fadeToggle();
	});
}

function menu(btn){
	btn.click(function(event) {
		event.preventDefault();
		btn.parent().toggleClass('open');
	});
}

/*this function add a class to header when scroll down the page
/* and change the logo by a new file if needed depending of mockup
*/
var firstEntry = false;
function scrollDownAction(topNumber, classHeader) {


    var logoHeader = document.querySelector('.js-scroll-logo img' ) || [];
    var getLogoUrl;
    var getDataLogo;

    if(logoHeader.length !== 0) {
        getLogoUrl = logoHeader.src || [];
        getDataLogo = logoHeader.getAttribute('data-scroll') || [];
    }

    var headerElement = document.querySelector('header' );

    if(!firstEntry) {
        console.log(getDataLogo.length);
        if(getDataLogo.length > 0) {
            window.addEventListener('scroll', function () {

                if( pageYOffset > topNumber ) {
                    headerElement.classList.add( classHeader );
                    logoHeader.src = getDataLogo;
                } else {
                    headerElement.classList.remove( classHeader );
                    logoHeader.src = getLogoUrl;
                }

            });
        } else {
            window.addEventListener('scroll', function () {

                if( pageYOffset > topNumber ) {
                    headerElement.classList.add( classHeader );
                } else {
                    headerElement.classList.remove( classHeader );
                }

            });
        }
        firstEntry = true;
    }
}


//Decorate links using GA4
function decorateUrlGA4(urlString){
    var generateLinkerParam = function(a) {
        // Function to properly grab ID's from Cookies
        var getCookiebyName = function(name) {
            var pair = document.cookie.match(new RegExp(name + '=([^;]+)'));
            return !!pair ? pair[1].match(/GA1\.[0-9]\.(.+)/)[1] : undefined;
        };
    
        // These are the 3 values used by the new linker
        var cookies = {
            _ga: getCookiebyName("_ga"),
            // Google Analytics GA ID
            _gac: undefined,
            // Google Remarketing
            _gid: getCookiebyName("_gid")// Google ID
        };
    
        // Calculate current browser_fingerprint based on UA, time, timezone and language
        // 
        var browser_fingerprint = (function(a, b) {
            var F = function(a) {
                // Didnt check what this does, the algo just needs F to be defined. commenting out
                Ne.set(a)
            };
            a = [window.navigator.userAgent, (new Date).getTimezoneOffset(), window.navigator.userLanguage || window.navigator.language, Math.floor((new Date).getTime() / 60 / 1E3) - (void 0 === b ? 0 : b), a].join("*");
            if (!(b = F)) {
                b = Array(256);
                for (var c = 0; 256 > c; c++) {
                    for (var d = c, e = 0; 8 > e; e++)
                        d = d & 1 ? d >>> 1 ^ 3988292384 : d >>> 1;
                    b[c] = d
                }
            }
    
            F = b;
            b = 4294967295;
            for (c = 0; c < a.length; c++)
                b = b >>> 8 ^ F[(b ^ a.charCodeAt(c)) & 255];
            return ((b ^ -1) >>> 0).toString(36);
        }
        )();
    
        // Function to hash the cookie value
        // The following functions takes a string and returns a hash value.
        var hash_cookie_value = function(val) {
            var A, C, D = function(a) {
                A = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_.";
                C = {
                    "0": 52,
                    "1": 53,
                    "2": 54,
                    "3": 55,
                    "4": 56,
                    "5": 57,
                    "6": 58,
                    "7": 59,
                    "8": 60,
                    "9": 61,
                    "A": 0,
                    "B": 1,
                    "C": 2,
                    "D": 3,
                    "E": 4,
                    "F": 5,
                    "G": 6,
                    "H": 7,
                    "I": 8,
                    "J": 9,
                    "K": 10,
                    "L": 11,
                    "M": 12,
                    "N": 13,
                    "O": 14,
                    "P": 15,
                    "Q": 16,
                    "R": 17,
                    "S": 18,
                    "T": 19,
                    "U": 20,
                    "V": 21,
                    "W": 22,
                    "X": 23,
                    "Y": 24,
                    "Z": 25,
                    "a": 26,
                    "b": 27,
                    "c": 28,
                    "d": 29,
                    "e": 30,
                    "f": 31,
                    "g": 32,
                    "h": 33,
                    "i": 34,
                    "j": 35,
                    "k": 36,
                    "l": 37,
                    "m": 38,
                    "n": 39,
                    "o": 40,
                    "p": 41,
                    "q": 42,
                    "r": 43,
                    "s": 44,
                    "t": 45,
                    "u": 46,
                    "v": 47,
                    "w": 48,
                    "x": 49,
                    "y": 50,
                    "z": 51,
                    "-": 62,
                    "_": 63,
                    ".": 64
                };
                for (var b = [], c = 0; c < a.length; c += 3) {
                    var d = c + 1 < a.length
                      , e = c + 2 < a.length
                      , g = a.charCodeAt(c)
                      , f = d ? a.charCodeAt(c + 1) : 0
                      , h = e ? a.charCodeAt(c + 2) : 0
                      , p = g >> 2;
                    g = (g & 3) << 4 | f >> 4;
                    f = (f & 15) << 2 | h >> 6;
                    h &= 63;
                    e || (h = 64,
                    d || (f = 64));
                    b.push(A[p], A[g], A[f], A[h])
                }
                return b.join("")
            };
            return D(String(val));
        };
    
        // Now we have all the data Let's build the linker String! =)
        // First value is a fixed "1" value, the current GA code does the same. May change in a future
        return ["1", browser_fingerprint, "_ga", hash_cookie_value(cookies._ga), "_gid", hash_cookie_value(cookies._gid)].join('*');
    };

    var linkerParam = generateLinkerParam();

    var url = new URL(urlString);

    url.searchParams.append('_gl' , linkerParam);

    return url.toString();
}


/* Load Sharing AddThis files asynchronously
 ****************************************************************/
var sym_share = {

	addThisUserid : 'ra-55ae99e6304f4e8b',
	shareButtonSelector: '.share-btn',

	init: function() {

		var $addthisButton = $('.addthis_button_compact');
		var $shareButton = $(this.shareButtonSelector);

		$shareButton.on('click', function(e) {

			e.preventDefault();

			var url = '//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-55ae99e6304f4e8b'
			$.getScript( url, function() {

				console.log(url);

				if (!addthis) {
						return;
					}

				var attempts = 0;
				var addthisinterval = window.setInterval(function() {

					addthis.toolbox('.addthis_toolbox')

					// AddThis is slow, so we'll wait for a maximum of 15 seconds
					if (attempts > 50) {
						window.clearInterval(addthisinterval);
					}

					if ($addthisButton[0].onclick) {
						$addthisButton.trigger('click');
						window.clearInterval(addthisinterval);
					}

					attempts += 1;

				}, 300);
			});

		});

	}
};

var _responsive = false;

if (typeof responsive != 'undefined') {
    var _responsive = responsive;
}

// Get token stored in session or create new one if not available. The function uses ajax to get the token to be 
// compatible with Page Cache Integration
function _get_form_token(){
    $.get(
        site_url + '/ajax/functions.php?operation=_get_form_token',
        function(resp) {
            $("input[name=_token][type=hidden]").each( function(){
                this.value = resp
            })		
        }		
    );
}

$(window).on("load", function () {

    $('#loading').fadeOut(300);
    $('#start-content').fadeIn(1);

    var redirecting = false;
    console.log(_responsive);

    $(window).resize(function () {
        if($(window).width() < 768 && !redirecting && !_responsive) {
            Cookies.set('forceMobile', 1, {'path' : '/'});
            redirecting = true;
            console.log('redirecting...');
            window.location = window.location;

        }
    });

    /* Universal Analytics cross domain tracking. Cross browser way to listen for events. */
    function addListener(element, type, callback) {
        if (element.addEventListener)
            element.addEventListener(type, callback);
        else if (element.attachEvent)
            element.attachEvent('on' + type, callback);
    }
    function decorateForm(event) {
        event = event || window.event;
        var target = event.target || event.srcElement;

        if (target && target.action) {
            ga('linker:decorate', target);
        }
    }

    if ($('#booking-form').length > 0) {
        var bookForm = document.getElementById('booking-form');
        addListener(bookForm, 'submit', decorateForm);
    }

    /*FANCY BOX*/
    $(".fancybox").fancybox({
        'padding' : 0,
        beforeShow: function () {
            var imgAlt = $(this.element).find("img").attr("alt");
            var dataAlt = $(this.element).data("alt");
            if (imgAlt) {
                $(".fancybox-image").attr("alt", imgAlt);
            } else if (dataAlt) {
                $(".fancybox-image").attr("alt", dataAlt);
            }
        },
        afterShow: function(){
            $('.fancybox-prev').focus();
        },
        afterLoad: function(current, previous) {
            $('html').addClass('fancybox-lock');
        },
        afterClose: function(){
            $('html').removeClass('fancybox-lock');
        }
    });
    $(".fancybox_iframe").fancybox({
        'type': 'iframe',
        'scrolling': 'no',
        'titleShow': false,
        'padding' : 0
    });
    $(".fancybox_inline").fancybox({
        'type': 'inline',
        'scrolling': 'no',
        'titleShow': false,
        'padding' : 0
    });
    $(".fancybox_youtube").click(function () {
        $.fancybox({
            'padding': 0,
            'autoScale': false,
            'transitionIn': 'none',
            'transitionOut': 'none',
            'title': this.title,
            'width': 680,
            'height': 495,
            'href': this.href.replace(new RegExp("watch\\?v=", "i"), 'v/'),
            'type': 'swf',
            'swf': {
                'wmode': 'transparent',
                'allowfullscreen': 'true'
            }
        });

        return false;
    });

    /*EASY SCROLL*/
    $('.scroll').click(function () {
        var target = $(this).attr("href");
        $('html, body').animate({scrollTop: $(target).offset().top - $('header').height()}, 1000);
        return false;
    });

    /*DATE PICKER*/
    $('.datepicker').datepicker();

    // Get Token and populate on all required forms 
    _get_form_token()


    /*BLOG ARCHIEVE*/
    $('ul.blog-archive li a.year').click(function () {
        if ($(this).hasClass('current')) {
            $(this).siblings('ul').slideUp('slow', function () {
                $(this).removeClass('current');
            });
        } else {
            $(this).siblings('ul').slideToggle('slow', function () {
                $(this).toggleClass('current');
            });
        }
        return false;
    });


    /*POST PAGINATION
     *****************************************************************/
    $(document).on('click', '#postLoadMore', function () {

        var numItems = $('.post').length;
        var URL = SITE_ROOT + "ajax/functions.php";
        var blog_id = $(this).attr('data-blog-id');
        var perpage = $(this).attr('data-per-page');
        var params = $(this).attr('data-params');


        $.post(URL + '?' + params, {offset: numItems, blogid: blog_id, perpage: perpage, operation: 'post_pagination'}, function (data) {
            if (data == '') {
                $("#postLoadMore").fadeOut();
            } else {
                $("#divPosts").append(data);
            }

        }, 'html');


        return false;
    });


    /*Capture all booking form submissions and save its value in the database
    *****************************************************************/
    $("#booking-form, #booking-form2").submit(function(){
        const formId = '#'+$(this).attr('id');

        var dates = [];
        var data = {};

        var _class = 'undefined';

        if ($(".hasDatepicker", $(formId)).length > 1) {
            _class = '.hasDatepicker';
        }

        //Work around for booking widgets using datepicker with multiple columns
        else if ($("._booking_search", $("#booking-form")).length > 1) {
            _class = '._booking_search';
        }

        $( _class , $(formId)).each(function(i,e){
            var value = $(e).val();
            dates.push(value);
        });

        data.operation = 'process_checkout';
        data.checkin = dates[0];
        data.checkout = dates[1];
        data.device = 'D';
        data.raw = $(formId).serialize();

        $.post(site_url + 'ajax/functions.php', data, function(resp){

        }, 'json');

    });

});

//Script lazy load
const load = () => {
    document.querySelectorAll("script[data-type='lazy']").forEach(el => el.setAttribute("src", el.getAttribute("data-src")));
}
const timer = setTimeout(load, 3000);
const trigger = () => {
    load();
    clearTimeout(timer);
}
const events = ["mouseover","keydown","touchmove","touchstart"];
events.forEach(e => window.addEventListener(e, trigger, {passive: true, once: true}));